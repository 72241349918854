.wrapper {
  background-color: #0362fd;
  padding: 4rem 2rem;
  color: white;
  text-align: left;
  p {
    margin-bottom: 1rem;
  }
  .news_list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 4rem;
    gap: 2rem;
    & > div {
      flex-basis: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      img {
        width: 100%;
      }

      .description {
        margin-top: 1rem;
        font-size: 12px;

        span {
          color: white;
          font-weight: 900;
        }
      }

      @media (min-width: 600px) {
        img {
          margin-bottom: 1rem;
        }
        flex-basis: 45%;
        gap: 0;
      }
    }
  }
}
