.wrapper {
  padding: 4rem 2rem;
  color: white;
  text-align: left;
  .colleagues_list {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 4rem;
    gap: 2rem;
    & > div {
      flex-basis: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 1rem;
      img {
        width: 140px;
        height: 140px;
      }
      .title {
        font-weight: bold;
        text-align: center;
      }
      .job_title {
        text-align: center;
      }
      .description {
        margin-top: 1rem;
        font-size: 12px;
      }
      @media (min-width: 600px) {
        img {
          margin-bottom: 1rem;
        }
        flex-basis: 30%;
        gap: 0;
      }
    }
  }
}
