.wrapper {
  background-color: #0362fd;
  background-size:0 0;
  background-image: url('~/public/news-background.jpg');
  background-size: cover;
  padding: 4rem 2rem;
  color: white;
  text-align: left;

  .news_list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 4rem;
    gap: 2rem;
    & > div {
      flex-basis: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      img {
        width: 100%;
        height: 190px;
      }
      .title {
        font-weight: bold;
        text-align: center;
      }
      .description {
        margin-top: 1rem;
        font-size: 12px;
        text-align: justify;

        a {
          color: white;
        }
      }

      @media (min-width: 600px) {
        img {
          margin-bottom: 1rem;
        }
        flex-basis: 45%;
        gap: 0;
      }
    }
  }
}
