.newsletter_body {
  padding: 0;
  max-width: 830px;
  margin: auto;
  a {
    margin-left: 0.5rem;
  }
  .loader {
    text-align: center;
    color: white;
    margin: auto;
  }
  @media (min-width: 600px) {
    padding: 0 40px 20px 40px;
  }
}

.heroDescription {
  margin: 20px;
}

.heroImage {
  margin: 20px;
}

.image {
  width: 100%;
}

.heroContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3;
}
