.wrapper {
  background-color: #f3f3f3;
  padding: 5rem 2rem;

  h2 {
    color: black;
    background-color: #f3f3f3;
  }

  .text_section {
    margin-top: 5.6rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;

    .paragraph {
      display: flex;
      flex-direction: column;
      gap: 3rem;
  
      p {
        line-height: 1.7rem;
        text-align: justify;
      }
      span {
        margin-right: 1rem;
        font-weight: 800;
      }
    }
  
    @media (min-width: 600px) {
      flex-direction: row;
      & > div {
        width: 50%;
      }
    }
  }
}
