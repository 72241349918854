* {
  margin: 0;
  padding: 0;
}

body,
html {
  font-family: Avenir, Arial, sans-serif;
  background-color: black;
  font-size: 16px;
}

