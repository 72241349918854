.header {
  padding: 3rem 2rem;
  color: white;
  background-color: #111 !important;
  text-align: left;
  background: url("https://raw.githubusercontent.com/BraneOpacicBM/imgHostr/main/images/purple-blue-wallpaper-with-colorful-swirl-2.png");
  background-size: cover;

  .header_text {
    margin-top: 5.6rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    padding-bottom: 2rem;

    h1 {
      font-size: 64px;
      margin-bottom: 3rem;
    }

    .header_paragraph {
      display: flex;
      flex-direction: column;
      gap: 3rem;

      p {
        line-height: 1.7rem;
      }
      span {
        font-weight: 800;
        margin-right: 1rem;
      }
    }

    @media (min-width: 600px) {
      flex-direction: row;
      & > div {
        width: 50%;
      }
    }
  }
}
